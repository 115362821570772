<template>
    <div class="card">
        <div class="mb-3">
            <Profiler :user="item.user" />
        </div>
        <div 
            class="flex items-center cursor-pointer mb-3" 
            @click="clickHandler()">
            <div class="pr-2">
                <a-avatar 
                    :size="32" 
                    icon="team" 
                    :src="item.organization.logo ? item.organization.logo : null" />
            </div>
            <span class="blue_color group_name truncate">
                {{ item.organization.name }}
            </span>
        </div>
        <div class="mb-2">
            <span class="gray">Дата создания:</span> {{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}
        </div>
        <div class="mb-2">
            <span class="gray">Дата обновления:</span> {{ $moment(item.updated_at).format('DD.MM.YYYY HH:mm') }}
        </div>
        <template v-if="item.is_touched">
            <a-tag v-if="item.is_approved" color="green">
                Одобрено
            </a-tag>
            <a-tag v-else color="red">
                Отклонено
            </a-tag>
        </template>
        <div v-else class="grid gap-2 grid-cols-2 mt-4">
            <a-button type="success" :loading="loading" ghost block @click="changeApproved(true)">
                Одобрить
            </a-button>
            <a-button type="danger" :loading="loading" ghost block @click="changeApproved(false)">
                Отклонить
            </a-button>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus'
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        clickHandler() {
            let query = Object.assign({}, this.$route.query)
            if(!query?.organization_drawer) {
                query.organization_drawer = 'detail'
                query.organization_id = this.item.organization.id
                this.$router.push({name: 'team', query})
            }
        },
        async changeApproved(is_approved) {
            try {
                this.loading = true
                const { data } = await this.$http.put(`/catalogs/profile_requests/${this.item.id}/`, {
                    is_approved
                })
                if(data) {
                    this.$message.success('Заявка обновлена')
                    eventBus.$emit('update_moderation_list')
                }
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка обновления')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.card{
    padding: 12px;
    background: #ffffff;
    border-radius: var(--borderRadius);
}
</style>